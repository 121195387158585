@tailwind base;
@tailwind components;
@tailwind utilities;

html, #root {
  @apply min-h-screen;
}

body {
  @apply m-0 p-0 font-sans bg-blue-100 min-h-screen;
}

form {
  @apply p-5;
}

form button, form h5 {
  @apply my-5;
}

.sidebar {
  @apply bg-gray-100 h-screen fixed top-0 left-0 p-5 overflow-y-auto;
}

.main-content {
  @apply p-5 ml-[300px];
}

.custom-radio .form-check-input {
  @apply bg-white border-gray-800 text-gray-800;
}

.title {
  @apply text-2xl mb-5;
}

.sub-title {
  @apply text-lg mb-2;
}

.detail {
  @apply text-sm mt-2;
}

.background-image, .login-image {
  @apply absolute bg-contain bg-no-repeat bg-center min-w-full min-h-full opacity-100;
}

.App {
  @apply w-full h-full;
}

.navbar-toggler-icon {
  @apply text-xs;
}

button.navbar-toggler {
  @apply p-2 w-auto;
}

.btn {
  @apply bg-pink-500 text-white hover:bg-pink-600;
}
